import { Injectable } from "@angular/core";
import { OversightApiService } from "app-modules/core/services/oversight-api.service";
import { ServerRequestAction, ServerRequestCompleteAction } from "app-modules/core/store/actions/custom-actions";
import { DashhboardSettingsRequest, DomoAuthResponse, DomoAccessTokenModel } from "emr-ng-shared";
import { Observable, of, Subject } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { EMBED_URL, SIGMA_EMBED_PATH, SIGMA_EMBED_SECRET } from "../models/domo-config-consts";
import { DomoDashboardDetails } from "../models/domo-dashboard-details.model";
import { DomoConnect } from "./domo-connect.service";
import { Store } from "@ngrx/store";
import { IAppState } from "app-modules/core/store/models/app-state.interface";
import { DomoUIStateService } from "../store/domo.state.service";
import * as CryptoJS from 'crypto-js';
import { v4 as uuidv4 } from 'uuid';

@Injectable()
export class DomoUIService {
    private isTermsAccepted = new Subject();
    public isTermsAccepted$ = this.isTermsAccepted.asObservable();
    constructor(
        private connectSvc: DomoConnect,
        private oversightSvc: OversightApiService,
        private domoUIStateSvc: DomoUIStateService
    ) { }

    dashboardTermsAccepted() {
        this.isTermsAccepted.next(true);
    }

    getAccessToken(): Observable<DomoAccessTokenModel> {
        return this.oversightSvc.GetDomoAccessToken();
    }

    getDomoFrameSource(): Observable<DomoDashboardDetails> {
        return of(null).pipe(
            switchMap(n => this.getAccessToken()),
            switchMap(n => this.oversightSvc.GetDomoEmbedToken(n.access_token)),
            map(n => this.getDomoDashboardDetails(n))
        );
    }

    getDomoDashboardDetails(n: DomoAuthResponse): DomoDashboardDetails {
        return {
            EmbedIframeSource: this.getEmbedIframSource(`${EMBED_URL}${n?.embedTokenId}`, n?.authentication),
            ShowPremiumLink: n?.ShowPremiumLink,
            IsTrialStarted: n?.IsTrialStarted,
            IsTrialEnded: n?.IsTrialEnded,
            DisplayReset: n?.DisplayReset,
            TrialMessage: n?.TrialMessage,
        };
    }

    private doesFrameSupportsSrcDoc(): boolean {
        const supportsSrcdoc = ('srcdoc' in document.createElement('iframe'));
        return !!supportsSrcdoc;
    }

    getLoadingUISrc(): string {
        const iframeSource = `
            <html lang="en">

                <head>
                <meta charset="utf-8">
                <title>Loading Domo site</title>
                <style type="text/css">
                    @keyframes spinner {
                        0% {
                            -webkit-transform: rotate(0);
                            transform: rotate(0)
                        }

                        100% {
                            -webkit-transform: rotate(360deg);
                            transform: rotate(360deg)
                        }
                    }

                    @-webkit-keyframes spinner {
                        0% {
                            -webkit-transform: rotate(0);
                            transform: rotate(0)
                        }

                        100% {
                            -webkit-transform: rotate(360deg);
                            transform: rotate(360deg)
                        }
                    }

                    @keyframes spinner_reverse {
                        0% {
                            -webkit-transform: rotate(0);
                            transform: rotate(0)
                        }

                        100% {
                            -webkit-transform: rotate(-360deg);
                            transform: rotate(-360deg)
                        }
                    }

                    @-webkit-keyframes spinner_reverse {
                        0% {
                            -webkit-transform: rotate(0);
                            transform: rotate(0)
                        }

                        100% {
                            -webkit-transform: rotate(-360deg);
                            transform: rotate(-360deg)
                        }
                    }

                    .spinner {
                        position: relative
                    }

                    .spinner div {
                        position: absolute;
                        width: 120px;
                        height: 120px;
                        top: 40px;
                        left: 40px;
                        border-radius: 50%;
                        border: 6px solid #000;
                        border-color: #df1317 transparent;
                        -webkit-animation: spinner 2s linear infinite;
                        animation: spinner 2s linear infinite
                    }

                    .spinner div:nth-child(2) {
                        width: 104px;
                        height: 104px;
                        top: 48px;
                        left: 48px;
                        border-color: transparent #e4934b;
                        -webkit-animation: spinner_reverse 2s linear infinite;
                        animation: spinner_reverse 2s linear infinite
                    }

                    .spinner {
                        width: 171px !important;
                        height: 171px !important;
                        -webkit-transform: translate(-85.5px, -85.5px) scale(.855) translate(85.5px, 85.5px);
                        transform: translate(-85.5px, -85.5px) scale(.855) translate(85.5px, 85.5px)
                    }

                    #overlay {
                        position: fixed;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        background-color: rgba(0, 0, 0, 0.4);
                        z-index: 12000;
                        cursor: pointer;
                    }

                    .d-none {
                        display: none;
                    }
                </style>
                </head>

                <body>
                    <div style="width:100%;height:100%;margin:auto; margin-top:43vh" class="spinner">
                        <div></div>
                        <div></div>
                    </div>
                </body>

            </html>`;

        return iframeSource;
    }

    getEmbedIframSource(embedUrl, embedToken): string {
        const iframeSource = `
            <html>
                <body> 
                    <form id='form' action='${embedUrl}' method='post'> 
                    <input type='hidden' name='embedToken' value='${embedToken}' /> 
                    </form> 
                    <script> 
                        document.getElementById('form').submit(); 
                    </script>
                </body>
            </html>`;

        return iframeSource;
    }

    setFrameSrc(frame: any, src: string) {
        frame.srcdoc = src;
        if (!this.doesFrameSupportsSrcDoc()) {
            frame.dataset.src = src;
            const js = 'javascript: window.frameElement.getAttribute("data-src")';
            frame.setAttribute('src', js);
            frame.contentWindow.location = js;
        }
    }


    public updateDashboardTrialSettings(request: DashhboardSettingsRequest): Observable<DomoDashboardDetails> {
        return of(null).pipe(
            map(() => this.domoUIStateSvc.DisplayLoader()),
            switchMap(() => this.oversightSvc.UpdateDashboardTrialSettings(request)),
            map(
                n => {
                    this.domoUIStateSvc.CancelLoader();
                    return this.getDomoDashboardDetails(n)
                },
                e => {
                    this.domoUIStateSvc.CancelLoader();
                }
            ),
            catchError(() => {
                this.domoUIStateSvc.CancelLoader();
                return of(null);
            })
        );
    }

    public updateDashboardPremiumSettings(request: DashhboardSettingsRequest) {
        return of(null).pipe(
            map(() => this.domoUIStateSvc.DisplayLoader()),
            switchMap(() => this.oversightSvc.UpdateDashboardPremiumSettings(request)),
            map(
                n => {
                    this.domoUIStateSvc.CancelLoader();
                    return n;
                },
                e => this.domoUIStateSvc.CancelLoader()
            ),
            catchError(() => {
                this.domoUIStateSvc.CancelLoader();
                return of(null);
            })
        );
    }

    getEmbedURLwithParameters(clientid: string, temperature: string, customerid: string, email: string, external_user_id: string, external_user_team: string, account_type: string, mode: string, hide_folder_navigation: boolean, show_footer: boolean): string {
        const nonce = uuidv4();
        const timestamp = Math.floor((new Date().getTime() - 15 * 60 * 1000) / 1000);
        const sessionLength = 3600; // 1 hour 
        const searchParams = `?:client_id=${clientid}&TemperatureUOM=${temperature}&CUSTOMERID=${customerid}&:nonce=${nonce}&:time=${timestamp}&:session_length=${sessionLength}&:hide_folder_navigation=${hide_folder_navigation}&:show_footer=${show_footer}&:email=${email}&:external_user_id=${external_user_id}&:external_user_team=${external_user_team}&:account_type=${account_type}&:mode=${mode}`;
        const URL_WITH_SEARCH_PARAMS = SIGMA_EMBED_PATH + searchParams;
        const signature = CryptoJS.HmacSHA256(URL_WITH_SEARCH_PARAMS, SIGMA_EMBED_SECRET).toString(CryptoJS.enc.hex);
        return `${searchParams}&:signature=${signature}`;
    }
}