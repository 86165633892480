import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DomoUIService } from '../services/domo.ui.service';
import { BusinessRulesService } from 'app-modules/core/store/business-rules/business-rules.service';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { take } from 'rxjs/operators';
import { IListInfo } from 'app-modules/core/store/models/list-info-state.interface';
import { BusinessRuleInfo, BusinessRuleType } from 'emr-ng-shared';
import { SIGMA_CLIENT_ID, SIGMA_EMBED_PATH } from '../models/domo-config-consts';

@Component({
  selector: 'app-sigma-embed-dashboard',
  templateUrl: './sigma-embed-dashboard.component.html',
  styleUrls: ['./sigma-embed-dashboard.component.css']
})
export class SigmaEmbedDashboardComponent implements OnInit, OnDestroy {
  sigmaUrl: string = "";
  constructor(
    private domoUIService: DomoUIService,
    private businessRulesService: BusinessRulesService,
    private router: Router
  ) { }

  ngOnDestroy(): void {
    this.sigmaUrl = "";
  }
  ngOnInit(): void {
    this.businessRulesService.businessRulesList$.pipe(take(1)).subscribe((businessRuleInfo: IListInfo<BusinessRuleInfo>) => {
      if (businessRuleInfo.list && businessRuleInfo.list.length > 0 &&
        environment.showDashboardLink && businessRuleInfo.list.find(br => br.BusinessRuleTypeCode === BusinessRuleType.DisplayScorecard)?.Enabled) {
        this.loadDashboard();
      } else {
        this.router.navigate(['']);
      }
    });
  }

  loadDashboard() {
    const clientId = SIGMA_CLIENT_ID;
    const customerId = JSON.parse(localStorage.getItem(environment.selectedCustomer));
    const localStorageData = JSON.parse(localStorage.getItem(environment.authPersistentData));
    const email = 'sam@test.com';
    const external_user_id = 'sam@test.com';
    const external_user_team = 'cargo_users';
    const account_type = 'EmbedEssential';
    const mode = 'userbacked';
    const hide_folder_navigation = true;
    const show_footer = false;
    const temperature = localStorageData ? localStorageData.TemperatureUnits : 'C';
    const urlWithParams = this.domoUIService.getEmbedURLwithParameters(clientId, temperature, customerId, email, external_user_id, external_user_team, account_type, mode, hide_folder_navigation, show_footer);
    this.sigmaUrl = `${SIGMA_EMBED_PATH}${urlWithParams}`;
  }
}
