import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewInit, TemplateRef } from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';

import { Shipment } from 'app-modules/core/models/shipment.model';
import { ShipmentService } from 'app-modules/core/services/shipment.service';
import { OversightPopupService } from 'app-modules/core/services/oversight-popup-service';
import { IShipmentDetailsInfo } from 'app-modules/core/models/shipment-detail-info.interface';
import { ShipmentSortService } from 'app-modules/core/services/shipment-sort.service';
import { BusinessRuleType, CustomTableColumnDefs, loadColumnDef, TripState, UpdateShipmentRequest, SetShutDownTrackerRequest } from 'emr-ng-shared';
import {
    shipmentName, serialNumber, shipmentStatus, temperatureRange, origin, destination
    , carrier, lastReportedTemp, lastReportedLocation, lastReportedTime, actualStartTime, actualEndTime, battery,
    notes, scheduledStart, scheduledEnd, lastReportedHumidity, lastReportedProbeTemperature, lastReportedCO2, lowWarnAlertCount,
    lowCriticalAlertCount, highWarnAlertCount, highCriticalAlertCount, totalTimeBelowRange, totalTimeAboveRange, totalTimeOutOfRange,
    maxTimeAboveRange, maxTimeBelowRange, avgSensorValue, meanKineticTemperature, minSensorValue, maxSensorValue, sensorStandardDeviation,
    humidityStandardDeviation, avgHumidityValue, maxHumidityValue, minHumidityValue, maxcarbonValue, mincarbonValue, maxProbeValue, minProbeValue,
    maxFuelLevelValue, minFuelLevelValue, avgCarbonValue, avgprobeValue, avgFuelLevelValue, carbonStandardDeviation, probeStandardDeviation, fuelLevelStandardDeviation, destinationETA, distanceToDestination, lastReportedProximity, plateNumber, containerNumber, driverPhoneNumber,
    label_origin_address, label_destination_address, label_origin_city, label_destination_city, label_origin_state, label_destination_state,
    label_origin_country, label_destination_country, label_origin_zip, label_destination_zip, scan_Reported, scan_Code, label_receiver_po, label_distribution_center, label_last_geo_fence, label_supplier
} from 'app-modules/core/consts/localization';
import { GetAlertSuspendStatusRequest, GetDistanceToTargetsRequest, SetTrackerNoteRequest, ShareTrackerRequest } from 'emr-ng-shared';
import { environment } from 'environments/environment';
import { ImageMapService } from 'app-modules/core/services/image-map.service';
import { ShipmentFilterService } from 'app-modules/core/store/shipment-filter/shipment-filter.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BusinessRulesService } from 'app-modules/core/store/business-rules/business-rules.service';
import { AuthService } from 'app-modules/core/store/auth/auth.service';
import { TrackerSensorRangesComponent } from '../sensor-range/components/tracker-sensor-ranges/tracker-sensor-ranges.component';
import { IShipmentFilterState } from 'app-modules/core/store/models/shipment-filter-state.interface';
import * as _ from 'lodash';
import { map, take, withLatestFrom } from 'rxjs/operators';
import { EmrCustomTableComponent } from 'emr-ng-shared';
import { IErrorInfo } from 'app-modules/core/models/error-info.interface';
import { ErrorMessageResponseComponent, EventArgs } from '../error-message-response/error-message-response.component';

@Component({
    selector: 'app-c-shipment-list',
    templateUrl: './shipment-list.component.html',
    styleUrls: ['./shipment-list.component.css']
})
export class ShipmentListComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() public mainDivCssClass: string;
    @Input() public dataDivCssClass: string;
    @Input() public dataDivHeight: string;
    @Input() public tablefilterHeight: string;
    @Input() public shipments$: Observable<Shipment[]>;
    private loadShipmentAddressSubscription: Subscription;
    private loadETASubscription: Subscription;
    SortColumn: string;
    @ViewChild(NgForm, { static: true }) shipmentShareForm: NgForm;
    @ViewChild('table', { static: true }) table: ElementRef;
    // Custom table
    @Input() columnDefs: CustomTableColumnDefs[];
    @Output() columnDefsChange = new EventEmitter();
    @ViewChild('detailsButton', { static: true }) detailsButton;
    @ViewChild('viewLocation', { static: true }) viewLocation;
    @ViewChild('updateETA', { static: true }) updateETA;
    @ViewChild('destETA', { static: true }) destETA;
    @ViewChild('tripStatus', { static: true }) tripStatus;
    @ViewChild('shipmentSerial', { static: true }) shipmentSerial;
    @ViewChild(EmrCustomTableComponent)
    private customTableComponent: EmrCustomTableComponent;
    @ViewChild('notes', { static: true }) notes;
    @ViewChild('notesModal') notesModal: TemplateRef<any>;
    @ViewChild('shareModal') shareModal: TemplateRef<any>;
    @ViewChild('qualityCode', { static: true }) qualityCode;
    public shipmentList$: Observable<Shipment[]>;
    // Custom table

    @Output() rowClick = new EventEmitter();
    @Output() refreshInfoBox = new EventEmitter();
    @Output() showRouteClicked = new EventEmitter<Shipment>();
    @Output() shareLocationClicked = new EventEmitter<Shipment>();
    @Output() showDetailsClicked = new EventEmitter<Shipment>();
    @Input() isHistorical = false;
    @Output() filterApply = new EventEmitter();
    @Output() updateMapMarkers = new EventEmitter<Shipment[]>();
    shipmentFilter: IShipmentFilterState;
    shipmentList: Shipment[];
    shipmentNote; string;
    emailAddresses: string;
    errorMessage: string;

    @Input() set AllShipmentsList(data: Shipment[]) {
        if (this.columnDefs?.length > 0 && data) {
            this.shipmentList = data;
            const filterCols = this.columnDefs.filter(k => k.isFilter);
            if (data.length > 0) {
                data.forEach(a => {
                    filterCols.forEach(b => {
                        if (a[b.value]) {
                            const valueMatcher = new RegExp(`^${a[b.valueField]}$`, 'i');
                            const listData = b.list.find(k => valueMatcher.test(k.value));
                            if (!listData) {
                                b.list.push({ text: a[b.value], value: a[b.valueField], count: 1, checked: false });
                            } else {
                                listData.count = listData.count + 1;
                            }
                        }
                    });
                });
            } else {
                filterCols.forEach(b => { b.list = []; });
            }
            setTimeout(() => {
                this.calculateFilterCounts(this.shipmentList);
            }, 10);
        }
    }

    public selectedSort$: Observable<string>;
    public isDescendingSort$: Observable<boolean>;
    selectedShipment: Shipment;
    shipmentSortedList$: Observable<Shipment[]>;
    Past90Days: number;
    showNewAlertIcon = environment.showAlertIconsShipmentList;
    bsModalRef: BsModalRef;
    businessRulesListSubscription: Subscription;
    allowSetSensors: boolean = null;
    showDSTAndDSTEvents = false;
    isVisibleFieldPlateNum = false;
    isVisibleFieldContainerNum = false;
    isVisibleFieldDriverPhoneNum = false;
    hideHumidity = false;
    testTrackerSensors: boolean;
    showCreateShipmentInDetails: boolean;
    authStateSub: Subscription;
    public userCanCreateShipment: boolean;
    public sort: any;
    tripState = TripState;
    ShowScannedTags = false;
    DVReceiverPONumberVisible = false;
    isUnAuthenticated = this.shpSvc.getUnAuthenticationToken();
    ShowSupplierField: boolean = false;
    isEndLocationUndefined: boolean = false;
    @Input() scrollToTop: EventEmitter<void>;

    constructor(
        public shpSvc: ShipmentService,
        private showPopups: OversightPopupService,
        private sortSvc: ShipmentSortService,
        private imgMapSvc: ImageMapService,
        public shpFltrSvc: ShipmentFilterService,
        private modalSvc: BsModalService,
        private businessruleSvc: BusinessRulesService,
        private authSvc: AuthService
    ) {
        this.shipmentSortedList$ = null;
    }

    sortedList = () => this.customTableComponent.sortedList();

    ngOnInit() {
        if (!this.isUnAuthenticated) {
            this.checkBusinessRule();
        }
        this.loadColumnDefinition();
        this.testTrackerSensors = environment.testTrackerSensors;
        this.showCreateShipmentInDetails = environment.showCreateShipmentInDetails;
        this.sort = JSON.parse(localStorage.getItem(environment.sortOrder));

        const today = new Date();
        const day = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0);
        this.Past90Days = day.setDate(day.getDate() - 89);
        this.selectedSort$ = this.sortSvc.selectedSort$;
        this.isDescendingSort$ = this.sortSvc.isDescending$;
        this.shipmentList$ = this.shipments$.pipe(withLatestFrom(this.shpFltrSvc.shipmentFilter$),
            map(([k, filter]) => {
                setTimeout(() => {
                    if (k && this.shipmentList) {
                        this.shipmentFilter = _.cloneDeep(filter);
                        this.calculateFilterCounts(this.shipmentList);
                    }
                });
                if (this.isUnAuthenticated && this.allowSetSensors === null) {
                    this.checkBusinessRule();
                }
                this.updateMapMarkers.emit(k);
                return k;
            }));

        this.authStateSub = this.authSvc.authState$.pipe().subscribe(n => {
            this.userCanCreateShipment = n?.canCreateShipment && (n?.canAccessOversight || n?.isEmersonAdmin);
        });
    }
    ngAfterViewInit() {

    }

    checkBusinessRule() {
        this.businessRulesListSubscription = this.businessruleSvc.businessRulesList$.subscribe(k => {
            if (k.list && k.list.length > 0) {
                this.allowSetSensors = k.list.find(br => br.BusinessRuleTypeCode ===
                    BusinessRuleType.AllowTempCheckOn3PLGOTrackersWOutTrips)?.Enabled;
                this.showDSTAndDSTEvents = k.list.find(br => br.BusinessRuleTypeCode ===
                    BusinessRuleType.ShowDSTAndDSTEvents)?.Enabled;
                this.isVisibleFieldPlateNum = k.list.find(br => br.BusinessRuleTypeCode ===
                    BusinessRuleType.IsVisibleFieldPlateNum)?.Enabled;
                this.isVisibleFieldContainerNum = k.list.find(br => br.BusinessRuleTypeCode ===
                    BusinessRuleType.IsVisibleFieldContainerNum)?.Enabled;
                this.isVisibleFieldDriverPhoneNum = k.list.find(br => br.BusinessRuleTypeCode ===
                    BusinessRuleType.IsVisibleFieldDriverPhoneNum)?.Enabled;
                this.ShowScannedTags = k.list.find(br => br.BusinessRuleTypeCode ===
                    BusinessRuleType.ShowScannedTags)?.Enabled;
                this.DVReceiverPONumberVisible = k.list.find(br => br.BusinessRuleTypeCode ===
                    BusinessRuleType.DVReceiverPONumberVisible)?.Enabled;
                this.ShowSupplierField = k.list.find(k => k.BusinessRuleTypeCode ===
                    BusinessRuleType.ShowSupplierField)?.Enabled;
                this.isEndLocationUndefined = k.list.find(k => k.BusinessRuleTypeCode ===
                    BusinessRuleType.EndLocationUndefined)?.Enabled;
                this.hideHumidity = k.list.find(k => k.BusinessRuleTypeCode ===
                    BusinessRuleType.HideHumidity)?.Enabled;
                //for Unauthenticated we need to refresh the column definitions after getting the Brules
                if (this.isUnAuthenticated) {
                    this.loadColumnDefinition();
                }
            }
        });
    }

    loadColumnDefinition() {
        const list: CustomTableColumnDefs[] = [
            loadColumnDef({
                title: '', value: '', isVisible: !this.isHistorical, actionName: 'details', width: '128px',
                isSortable: false, isCustomHtml: true,
                customHTML: this.detailsButton, columnSelectionOrder: 1, columnOrder: 1
            }),
            loadColumnDef({ title: shipmentName, value: 'tripName', width: '128px', columnSelectionOrder: 4, columnRowIndex: 1, columnOrder: 2 }),
            loadColumnDef({
                title: serialNumber, value: 'serialNumber', columnSelectionOrder: 3, width: '155px',
                customHTML: this.shipmentSerial, isCustomHtml: true, columnOrder: 5, columnRowIndex: 1,
            }),
            loadColumnDef({
                title: shipmentStatus, value: 'tripStatus', width: '129px', isFilter: true, valueField: 'tripStateCode',
                sortableColumn: 'tripStateCode', columnRowIndex: 1,
                customHTML: this.tripStatus, isCustomHtml: true, columnSelectionOrder: 5, columnOrder: 4
            }),
            loadColumnDef({
                title: temperatureRange, value: 'sensorRange', width: '145px', isFilter: true, valueField: 'SensorTempRangeId',
                columnSelectionOrder: 26, columnRowIndex: 3, showFilterSearch: true, columnOrder: 5
            }),
            loadColumnDef({ title: origin, value: 'origin', width: '126px', isFilter: true, valueField: 'originId', columnSelectionOrder: 14, columnRowIndex: 2, showFilterSearch: true, columnOrder: 6 }),
            loadColumnDef({ title: destination, value: 'destination', width: '129px', isFilter: true, valueField: 'destinationId', columnSelectionOrder: 21, columnRowIndex: 2, showFilterSearch: true, columnOrder: 7 }),
            loadColumnDef({ title: carrier, value: 'carrierName', width: '129px', isFilter: true, columnSelectionOrder: 10, columnRowIndex: 1, showFilterSearch: true, columnOrder: 8 }),
            loadColumnDef({ title: lastReportedTemp, value: 'temperature', width: '129px', sortableColumn: 'temperatureNumber', columnSelectionOrder: 29, columnRowIndex: 3, columnOrder: 9 }),
            loadColumnDef({
                title: lastReportedLocation, value: 'AddressGeo', isCustomHtml: true, width: '129px', customHTML: this.viewLocation,
                columnSelectionOrder: 28, columnRowIndex: 3, columnOrder: 10
            }),
            loadColumnDef({
                title: lastReportedTime, value: 'LastReportedTimestampFormatted', width: '129px', valueType: 'date',
                sortableColumn: 'LastReportedTimestamp', columnSelectionOrder: 27, columnRowIndex: 3, columnOrder: 11
            }),
            loadColumnDef({
                title: actualStartTime, value: 'actualStartTime', width: '129px', valueType: 'date',
                sortableColumn: 'ActualStartTime', columnSelectionOrder: 6, columnRowIndex: 1, columnOrder: 12
            }),
            loadColumnDef({
                title: actualEndTime, value: 'actualEndTime', width: '129px', valueType: 'date',
                sortableColumn: 'ActualEndTime', columnSelectionOrder: 8, columnRowIndex: 1, columnOrder: 13
            }),
            loadColumnDef({
                title: scheduledStart, value: 'scheduledStartTime', width: '140px', valueType: 'date',
                sortableColumn: 'ScheduledStartTime', isVisible: false, columnSelectionOrder: 7, columnRowIndex: 1, columnOrder: 14
            }),
            loadColumnDef({
                title: scheduledEnd, value: 'scheduledEndTime', width: '140px', valueType: 'date',
                sortableColumn: 'ScheduledEndTime', isVisible: false, columnSelectionOrder: 9, columnRowIndex: 1, columnOrder: 15
            }),
            loadColumnDef({
                title: battery, value: 'battery', isVisible: false, width: '50px',
                sortableColumn: 'batteryLevel', columnSelectionOrder: 2, columnRowIndex: 1, columnOrder: 16
            }),
            loadColumnDef({
                title: notes, value: 'notes', width: '140px', isVisible: true,
                columnSelectionOrder: 14, columnRowIndex: 1, customHTML: this.notes, isCustomHtml: true, columnOrder: 17
            }),
            loadColumnDef({
                title: lastReportedCO2, value: 'CarbonDioxideLevelString', width: '140px', isVisible: false,
                sortableColumn: 'CarbonDioxideLevel', columnSelectionOrder: 30, columnRowIndex: 3, columnOrder: 19
            }),
            loadColumnDef({
                title: lastReportedProbeTemperature, value: 'ProbeTemperatureString', width: '140px', isVisible: false,
                sortableColumn: 'ProbeTemperature', columnSelectionOrder: 32, columnRowIndex: 3, columnOrder: 20
            }),
            loadColumnDef({
                title: distanceToDestination, value: 'TripETADistanceString', width: '140px', isVisible: false,
                sortableColumn: 'TripETADistanceM', columnSelectionOrder: 11, columnRowIndex: 1, customHTML: this.updateETA,
                isCustomHtml: true, columnOrder: 47
            }),
            loadColumnDef({
                title: destinationETA, value: 'TripETAFormatted', width: '140px', isVisible: false,
                sortableColumn: 'TripETA', columnSelectionOrder: 12, columnRowIndex: 1, customHTML: this.destETA,
                isCustomHtml: true, columnOrder: 48
            }),

            loadColumnDef({
                title: label_origin_address, value: 'TripStartLocationFullAddress', width: '150px',
                columnSelectionOrder: 16, columnRowIndex: 2, columnOrder: 52, isVisible: false
            }),

            loadColumnDef({
                title: label_origin_city, value: 'TripStartLocationCity', width: '120px', columnSelectionOrder: 17, columnRowIndex: 2,
                columnOrder: 53, isVisible: false, isFilter: true, showFilterSearch: true
            }),

            loadColumnDef({
                title: label_origin_state, value: 'TripStartLocationState', width: '120px', columnSelectionOrder: 18, columnRowIndex: 2,
                columnOrder: 54, isVisible: false, isFilter: true, showFilterSearch: true
            }),

            loadColumnDef({
                title: label_origin_country, value: 'TripStartLocationCountry', width: '120px', columnSelectionOrder: 20, columnRowIndex: 2,
                columnOrder: 56, isVisible: false, isFilter: true, showFilterSearch: true
            }),

            loadColumnDef({
                title: label_origin_zip, value: 'TripStartLocationZipCode', width: '120px', columnSelectionOrder: 19, columnRowIndex: 2,
                columnOrder: 55, isVisible: false, isFilter: true, showFilterSearch: true
            }),


            loadColumnDef({
                title: label_destination_address, value: 'TripEndLocationFullAddress', width: '150px', columnSelectionOrder: 22, columnRowIndex: 2,
                columnOrder: 57, isVisible: false
            }),

            loadColumnDef({
                title: label_destination_city, value: 'TripEndLocationCity', width: '120px', columnSelectionOrder: 23, columnRowIndex: 2,
                columnOrder: 58, isVisible: false, isFilter: true, showFilterSearch: true
            }),

            loadColumnDef({
                title: label_destination_state, value: 'TripEndLocationState', width: '120px', columnSelectionOrder: 24, columnRowIndex: 2,
                columnOrder: 59, isVisible: false, isFilter: true, showFilterSearch: true
            }),

            loadColumnDef({
                title: label_destination_country, value: 'TripEndLocationCountry', width: '120px', columnSelectionOrder: 26, columnRowIndex: 2,
                columnOrder: 60, isVisible: false, isFilter: true, showFilterSearch: true
            }),

            loadColumnDef({
                title: label_destination_zip, value: 'TripEndLocationZipCode', width: '120px', columnSelectionOrder: 25, columnRowIndex: 2,
                columnOrder: 61, isVisible: false, isFilter: true, showFilterSearch: true
            })
        ];
        if (!this.hideHumidity) {
            list.push(
                loadColumnDef({
                    title: lastReportedHumidity, value: 'HumidityLevelString', width: '140px', isVisible: false,
                    sortableColumn: 'HumidityLevel', columnSelectionOrder: 31, columnRowIndex: 3, columnOrder: 18
                }))
        }
        if (this.showDSTAndDSTEvents) {
            list.push(
                loadColumnDef({
                    title: lastReportedProximity, value: 'ProximityString', width: '140px', isVisible: this.showDSTAndDSTEvents,
                    sortableColumn: 'Proximity', columnSelectionOrder: 49, columnOrder: 21, columnRowIndex: 3
                }));
        }

        if (this.isVisibleFieldPlateNum) {
            list.push(
                loadColumnDef({
                    title: plateNumber, value: 'PlateNum', width: '140px', isVisible: this.isVisibleFieldPlateNum,
                    sortableColumn: 'PlateNum', columnSelectionOrder: 50, inlineCheckbox: false, columnOrder: 49, columnRowIndex: 3
                }));
        }

        if (this.isVisibleFieldContainerNum) {
            list.push(
                loadColumnDef({
                    title: containerNumber, value: 'ContainerNum', width: '140px', isVisible: this.isVisibleFieldContainerNum,
                    sortableColumn: 'ContainerNum', columnSelectionOrder: 51, inlineCheckbox: false, columnOrder: 50, columnRowIndex: 3
                }));
        }
        if (this.isVisibleFieldDriverPhoneNum) {
            list.push(
                loadColumnDef({
                    title: driverPhoneNumber, value: 'DriverPhoneNum', width: '140px', isVisible: this.isVisibleFieldDriverPhoneNum,
                    sortableColumn: 'DriverPhoneNum', columnSelectionOrder: 52, inlineCheckbox: false, columnOrder: 51, columnRowIndex: 3
                }));
        }
        if (this.ShowScannedTags) {
            list.push(loadColumnDef({
                title: scan_Reported, value: 'SmartTagUploadFormatted', width: '129px', valueType: 'date',
                isVisible: this.ShowScannedTags, sortableColumn: 'SmartTagUpload', columnOrder: 49, columnSelectionOrder: 53, columnRowIndex: 3,
            }));
            list.push(
                loadColumnDef({
                    title: scan_Code, value: 'SmartTagQualityCode', width: '129px', isVisible: this.ShowScannedTags,
                    sortableColumn: 'SmartTagQualityCode', columnOrder: 50, customHTML: this.qualityCode, columnSelectionOrder: 54, columnRowIndex: 3,
                    isCustomHtml: true
                }));
        }

        if (this.DVReceiverPONumberVisible) {
            list.push(loadColumnDef({
                title: label_receiver_po, value: 'ReceiverPONum', width: '128px', columnSelectionOrder: 4,
                columnRowIndex: 1, columnOrder: 62, isVisible: false,
            }))
        }
        if (this.ShowSupplierField) {
            list.push(loadColumnDef({
                title: label_supplier, value: 'SupplierName', width: '120px', valueField: 'SupplierCustomerID', columnSelectionOrder: 55, columnRowIndex: 1,
                columnOrder: 63, isVisible: false, isFilter: true, showFilterSearch: true
            }));
        }
        if (this.isEndLocationUndefined) {
            list.push(loadColumnDef({
                title: label_distribution_center, value: 'DCTripStop', width: '120px', valueField: 'DCTripStopLocationID', columnSelectionOrder: 56, columnRowIndex: 1,
                columnOrder: 64, isVisible: false, isFilter: true, showFilterSearch: true
            }));

            list.push(loadColumnDef({
                title: label_last_geo_fence, value: 'MostRecentPostDC', width: '120px', columnSelectionOrder: 57, columnRowIndex: 1,
                columnOrder: 65, isVisible: false, isFilter: true, showFilterSearch: true
            }));
        }
        if (this.isHistorical) {
            const emptyValue = '';
            const emptyNumberValue = '0';
            const historicalCols = [
                loadColumnDef({
                    title: lowWarnAlertCount, value: 'LowWarnAlertCount', width: '140px', isVisible: false,
                    columnSelectionOrder: 24, groupCategory: 1, nullValue: emptyNumberValue, rowClass: "table-row-data text-center", columnOrder: 21
                }),
                loadColumnDef({
                    title: lowCriticalAlertCount, value: 'LowCriticalAlertCount', width: '140px', isVisible: false,
                    columnSelectionOrder: 25, groupCategory: 1, nullValue: emptyNumberValue, rowClass: "table-row-data text-center", columnOrder: 22
                }),
                loadColumnDef({
                    title: highWarnAlertCount, value: 'HighWarnAlertCount', width: '140px', isVisible: false,
                    columnSelectionOrder: 26, groupCategory: 1, nullValue: emptyNumberValue, rowClass: "table-row-data text-center", columnOrder: 23
                }),
                loadColumnDef({
                    title: highCriticalAlertCount, value: 'HighCriticalAlertCount', width: '140px', isVisible: false,
                    columnSelectionOrder: 27, groupCategory: 1, nullValue: emptyNumberValue, rowClass: "table-row-data text-center", columnOrder: 24
                }),

                loadColumnDef({
                    title: totalTimeBelowRange, value: 'TotalTimeBelowRangeString', width: '140px', valueField: 'TotalTimeBelowRange', isVisible: false,
                    columnSelectionOrder: 28, groupCategory: 1, nullValue: emptyNumberValue, columnOrder: 25
                }),
                loadColumnDef({
                    title: totalTimeAboveRange, value: 'TotalTimeAboveRangeString', width: '140px', valueField: 'TotalTimeAboveRange', isVisible: false,
                    columnSelectionOrder: 29, groupCategory: 1, nullValue: emptyNumberValue, columnOrder: 26
                }),
                loadColumnDef({
                    title: totalTimeOutOfRange, value: 'TotalTimeOutOfRangeString', width: '140px', valueField: 'TotalTimeOutOfRange', isVisible: false,
                    columnSelectionOrder: 30, groupCategory: 1, nullValue: emptyNumberValue, columnOrder: 27
                }),

                loadColumnDef({
                    title: maxTimeBelowRange, value: 'MaxTimeBelowRangeString', width: '140px', valueField: 'MaxTimeBelowRange', isVisible: false,
                    columnSelectionOrder: 31, groupCategory: 1, nullValue: emptyNumberValue, columnOrder: 28
                }),
                loadColumnDef({
                    title: maxTimeAboveRange, value: 'MaxTimeAboveRangeString', width: '140px', valueField: 'MaxTimeAboveRange', isVisible: false,
                    columnSelectionOrder: 32, groupCategory: 1, nullValue: emptyNumberValue, columnOrder: 29
                }),

                //Temperature groupCategory - 2
                loadColumnDef({
                    title: meanKineticTemperature, value: 'MeanKineticTemperatureString', width: '60px', valueField: 'MeanKineticTemperature', isVisible: false,
                    columnSelectionOrder: 33, groupCategory: 2, nullValue: emptyValue, columnOrder: 30
                }),
                loadColumnDef({
                    title: sensorStandardDeviation, value: 'SensorStandardDeviation', width: '125px', valueField: 'SensorStandardDeviation', isVisible: false,
                    columnSelectionOrder: 34, groupCategory: 2, nullValue: emptyValue, columnOrder: 31
                }),
                loadColumnDef({
                    title: avgSensorValue, value: 'AvgSensorValueString', width: '110px', valueField: 'AvgSensorValue', isVisible: false,
                    columnSelectionOrder: 35, groupCategory: 2, nullValue: emptyNumberValue, columnOrder: 32
                }),
                loadColumnDef({
                    title: minSensorValue, value: 'MinSensorValueString', width: '135px', valueField: 'MinSensorValue', isVisible: false,
                    columnSelectionOrder: 36, groupCategory: 2, nullValue: emptyValue, columnOrder: 33
                }),
                loadColumnDef({
                    title: maxSensorValue, value: 'MaxSensorValueString', width: '135px', valueField: 'MaxSensorValue', isVisible: false,
                    columnSelectionOrder: 37, groupCategory: 2, nullValue: emptyValue, columnOrder: 34
                }),

                //Carbondioxide groupCategory - 4
                loadColumnDef({
                    title: carbonStandardDeviation, value: 'CarbondioxideStandardDeviationString', width: '125px', valueField: 'CarbondioxideStandardDeviation', isVisible: false,
                    columnSelectionOrder: 42, groupCategory: 4, nullValue: emptyValue, columnOrder: 39
                }),
                loadColumnDef({
                    title: avgCarbonValue, value: 'AvgCarbondioxideString', width: '110px', valueField: 'AvgCarbondioxide', isVisible: false,
                    columnSelectionOrder: 43, groupCategory: 4, nullValue: emptyNumberValue, columnOrder: 40
                }),
                loadColumnDef({
                    title: mincarbonValue, value: 'MinCarbondioxideValueString', width: '135px', valueField: 'MinCarbondioxideValue', isVisible: false,
                    columnSelectionOrder: 44, groupCategory: 4, nullValue: emptyValue, columnOrder: 41
                }),
                loadColumnDef({
                    title: maxcarbonValue, value: 'MaxCarbondioxideValueString', width: '135px', valueField: 'MaxCarbondioxideValue', isVisible: false,
                    columnSelectionOrder: 45, groupCategory: 4, nullValue: emptyValue, columnOrder: 42
                }),

                //probe groupCategory - 5
                loadColumnDef({
                    title: probeStandardDeviation, value: 'ProbeStandardDeviationString', width: '125px', valueField: 'ProbeStandardDeviation', isVisible: false,
                    columnSelectionOrder: 46, groupCategory: 5, nullValue: emptyValue, columnOrder: 43
                }),
                loadColumnDef({
                    title: avgprobeValue, value: 'AvgProbeString', width: '110px', valueField: 'AvgProbe', isVisible: false,
                    columnSelectionOrder: 47, groupCategory: 5, nullValue: emptyNumberValue, columnOrder: 44
                }),
                loadColumnDef({
                    title: minProbeValue, value: 'MinProbeValueString', width: '135px', valueField: 'MinProbeValue', isVisible: false,
                    columnSelectionOrder: 48, groupCategory: 5, nullValue: emptyValue, columnOrder: 45
                }),
                loadColumnDef({
                    title: maxProbeValue, value: 'MaxProbeValueString', width: '135px', valueField: 'MaxProbeValue', isVisible: false,
                    columnSelectionOrder: 49, groupCategory: 5, nullValue: emptyValue, columnOrder: 46
                }),

                //fuellevel groupCategory - 6
                // loadColumnDef({
                //     title: fuelLevelStandardDeviation, value: 'FuelLevelStandardDeviationString', width: '125px', valueField: 'FuelLevelStandardDeviation', isVisible: false,
                //     columnOrder: 47, groupCategory: 6, nullValue: emptyValue
                // }),
                // loadColumnDef({
                //     title: avgFuelLevelValue, value: 'AvgFuelLevelString', width: '110px', valueField: 'AvgFuelLevel', isVisible: false,
                //     columnOrder: 48, groupCategory: 6, nullValue: emptyNumberValue
                // }),
                // loadColumnDef({
                //     title: minFuelLevelValue, value: 'MinFuelLevelValueString', width: '135px', valueField: 'MinFuelLevelValue', isVisible: false,
                //     columnOrder: 49, groupCategory: 6, nullValue: emptyValue
                // }),
                // loadColumnDef({
                //     title: maxFuelLevelValue, value: 'MaxFuelLevelValueString', width: '135px', valueField: 'MaxFuelLevelValue', isVisible: false,
                //     columnOrder: 50, groupCategory: 6, nullValue: emptyValue
                // })
            ];
            if (!this.hideHumidity) {

                //Humidity groupCategory - 3
                historicalCols.push(
                    loadColumnDef({
                        title: humidityStandardDeviation, value: 'HumidityStandardDeviationString', width: '125px', valueField: 'HumidityStandardDeviation', isVisible: false,
                        columnSelectionOrder: 38, groupCategory: 3, nullValue: emptyValue, columnOrder: 35
                    }));
                historicalCols.push(loadColumnDef({
                    title: avgHumidityValue, value: 'AvgHumidityString', width: '110px', valueField: 'AvgHumidity', isVisible: false,
                    columnSelectionOrder: 39, groupCategory: 3, nullValue: emptyNumberValue, columnOrder: 36
                }));
                historicalCols.push(loadColumnDef({
                    title: minHumidityValue, value: 'MinHumidityValueString', width: '135px', valueField: 'MinHumidityValue', isVisible: false,
                    columnSelectionOrder: 40, groupCategory: 3, nullValue: emptyValue, columnOrder: 37
                }));
                historicalCols.push(loadColumnDef({
                    title: maxHumidityValue, value: 'MaxHumidityValueString', width: '135px', valueField: 'MaxHumidityValue', isVisible: false,
                    columnSelectionOrder: 41, groupCategory: 3, nullValue: emptyValue, columnOrder: 38
                }));
            }

            list.push(...historicalCols);
        }
        const data = JSON.parse(localStorage.getItem(environment.columnDefs));
        if (data) {
            data.map(a => {
                const filterData = list.find(k => k.value === a.value);
                if (!filterData) { return; }
                filterData.columnOrder = a.columnOrder;
                filterData.isVisible = a.value === '' ? !this.isHistorical : a.isVisible;
            });
        }
        this.columnDefs = _.orderBy(list, ['columnOrder'], ['asc']);
        this.columnDefsChange.emit({ columns: this.columnDefs, sort: null, isColumnChange: false });
    }

    public ngOnDestroy() {
        if (this.authStateSub) {
            this.authStateSub.unsubscribe();
            this.authStateSub = null;
        }

        if (this.businessRulesListSubscription) {
            this.businessRulesListSubscription.unsubscribe();
            this.businessRulesListSubscription = null;
        }

        if (this.loadShipmentAddressSubscription) {
            this.loadShipmentAddressSubscription.unsubscribe();
        }
        this.sortSvc.changeShipmentSort('TripStateCode', false);
    }

    onShipmentRouteClick(shipment?: Shipment) {
        this.showRouteClicked.emit(shipment);
    }

    onShareLocationClick(shipment?: Shipment) {
        this.shareLocationClicked.emit(shipment);
    }

    onViewLocationClick(shipment: Shipment) {
        shipment.AddressGeoLoading = true;
        this.loadShipmentAddressSubscription = this.shpSvc.loadObservableShipmentAddress(shipment.trackerId).subscribe(k => {
            shipment.AddressGeoLoading = false;
            this.refreshInfoBox.emit(shipment);
            if (this.loadShipmentAddressSubscription) {
                this.loadShipmentAddressSubscription.unsubscribe();
                this.loadShipmentAddressSubscription = null;
            }
        });
    }

    onUpdateETAClick(shipment: Shipment) {
        shipment.TripETALoading = true;
        this.loadETASubscription = this.shpSvc.loadTripETA(shipment).subscribe(k => {
            shipment.TripETALoading = false;
            if (this.loadETASubscription) {
                this.loadETASubscription.unsubscribe();
                this.loadETASubscription = null;
            }
        });

    }

    onShipmentDetailsClick(shipment: Shipment) {
        this.showDetailsClicked.emit(shipment);
        const ShipmentDetails: IShipmentDetailsInfo = {
            tripId: shipment.tripId,
            trackerId: shipment.trackerId,
            customerTrackerId: shipment.customerTrackerId,
            isMultiTrip: shipment.isMultiTrip,
            IsDVShipment: shipment.IsDVShipment,
            tripStateCode: shipment.tripStateCode,
            ModelName: shipment.ModelName,
            ModelNumber: shipment.ModelNumber,
            Is4G: shipment.Is4G,
            ActualStartTime: shipment.ActualStartTime,
            ActualEndTime: shipment.ActualEndTime,
            ScheduledStartTime: shipment.ScheduledStartTime,
            ScheduledEndTime: shipment.ScheduledEndTime,
            LastReportedTimestamp: shipment.LastReportedTimestamp
        };
        this.showPopups.showShipmentDetailPopup(ShipmentDetails);
    }

    onRowClick(shipment: Shipment) {
        if (!this.isHistorical) {
            this.selectedShipment = shipment;
            this.rowClick.emit(shipment);
        }
    }

    sortSelected(selectedSort: string, dbName: string) {
        this.SortColumn = dbName;
        const newSort = this.sortSvc.calculateNewSort(selectedSort);
        this.sortSvc.ChangeShipmentSortWithOutListUpdate(selectedSort, newSort.isDescending);
    }

    CheckDatesInPast90Days(lastReported, actualEndTime, tripState) {
        // For pending trips we are not checking but for completed trips we are checking for actualEndTIme for other trips we are checking for LastreportedTime
        return tripState == TripState.Pending || (tripState == TripState.Completed ? new Date(actualEndTime).getTime() > this.Past90Days : new Date(lastReported).getTime() > this.Past90Days);
    }

    checkForNotReportedUnassignedTracker(shipment: Shipment) {
        return !shipment?.tripId && !shipment?.LastReportedTimestamp;
    }

    onCreateShipmentClick(shipment: Shipment) {
        if (this.userCanCreateShipment) {
            this.showPopups.showCreateShipmentPopup(shipment.trackerId, shipment.customerTrackerId, shipment.isMultiTrip);
        }
    }

    onSetTrackerSensorsClick(shipment: Shipment) {
        this.bsModalRef = this.modalSvc.show(
            TrackerSensorRangesComponent,
            {
                initialState: {
                    serialNumber: shipment.trackerId
                },
                class: 'modal-md modal-dialog-centered max-width-600',
                ignoreBackdropClick: true
            }
        );
    }

    onSuspendResumeAlertsClick(shipment: Shipment) {
        const alertSuspendStatusRequest = new GetAlertSuspendStatusRequest();
        alertSuspendStatusRequest.DeviceId = shipment.customerTrackerId;
        alertSuspendStatusRequest.GlobalDeviceId = shipment.trackerId;
        alertSuspendStatusRequest.TripId = shipment.tripId;

        this.showPopups.showAlertSuppressionPopup(alertSuspendStatusRequest);
    }

    onDistanceToClick(trackerId, customerTrackerId, tripId) {
        const distanceToTargetsRequest = new GetDistanceToTargetsRequest();
        distanceToTargetsRequest.TrackerId = trackerId;
        distanceToTargetsRequest.CustomerTrackerId = customerTrackerId;
        distanceToTargetsRequest.TripId = tripId;

        this.showPopups.showDistanceToPopup(distanceToTargetsRequest);
    }

    onDeleteShipmentClick(shipment: Shipment) {
        if (shipment?.CanDeleteTrip) {
            this.showPopups.showDeleteTrip(shipment);
        }
    }

    onCloseShipmentClick(shipment: Shipment) {
        if ((shipment.canCloseShipment)) {
            this.showPopups.showCloseShipmentPopup(shipment, true);
        }
    }

    calcImageURL(imageId: string, imageUrlSVG: string, imageUrl: string) {
        return this.imgMapSvc.getImageURL(imageId, imageUrlSVG, imageUrl);
    }

    onFilterApply(column: CustomTableColumnDefs) {
        if (this.filterApply) {
            this.filterApply.emit(column);
        }
    }

    calculateFilterCounts(data: Shipment[]) {
        this.columnDefs.forEach(b => {
            if (!b.isFilter) { return; }
            if (b.value) {
                const list = this.shpSvc.applyLocalFilter(data, this.getShipmentFilterWithOutCurrentColumn(b.value));
                b.list.forEach(a => {
                    const valueMatcher = new RegExp(`^${a.value}$`, 'i');
                    a.count = list.filter(k => valueMatcher.test(k[b.valueField]))?.length;
                });
                b.filterAppliedModel = this.getAppliedFilterValues(b.value);
                b.filteredList = _.orderBy(b.list.filter(i => i.checked || i.count > 0), ['count', 'checked'], ['desc', 'desc']);
            }
        });
    }

    getShipmentFilterWithOutCurrentColumn(title: string) {
        const filter = _.cloneDeep(this.shipmentFilter);
        if (!filter) { return filter; }
        switch (title) {
            case ('tripStatus'):
                filter.status = null;
                return filter;
            case ('sensorRange'):
                filter.sensorRange = null;
                return filter;
            case ('origin'):
                filter.origin = null;
                return filter;
            case ('destination'):
                filter.destination = null;
                return filter;
            case ('carrierName'):
                filter.carrier = null;
                return filter;
            case ('TripStartLocationCity'):
                filter.originCity = null;
                return filter;
            case ('TripStartLocationState'):
                filter.originState = null;
                return filter;
            case ('TripStartLocationCountry'):
                filter.originCountry = null;
                return filter;
            case ('TripStartLocationZipCode'):
                filter.originZip = null;
                return filter;
            case ('TripEndLocationCity'):
                filter.destinationCity = null;
                return filter;
            case ('TripEndLocationState'):
                filter.destinationState = null;
                return filter;
            case ('TripEndLocationCountry'):
                filter.destinationCountry = null;
                return filter;
            case ('TripEndLocationZipCode'):
                filter.destinationZip = null;
                return filter;
            case ('SupplierName'):
                filter.supplierCustomer = null;
                return filter;
            case ('DCTripStop'):
                filter.dcTripStopLocation = null;
                return filter;
            case ('MostRecentPostDC'):
                filter.mostRecentPostDC = null;
                return filter;
            default:
                return filter;
        }
    }

    getAppliedFilterValues(title: string) {
        const filter = this.shipmentFilter;
        if (!filter) { return []; }
        switch (title) {
            case ('tripStatus'):
                return filter.status;
            case ('sensorRange'):
                return filter.sensorRange;
            case ('origin'):
                return filter.origin;
            case ('destination'):
                return filter.destination;
            case ('carrierName'):
                return filter.carrier;
            case ('TripStartLocationCity'):
                return filter.originCity;
            case ('TripStartLocationState'):
                return filter.originState;
            case ('TripStartLocationCountry'):
                return filter.originCountry;
            case ('TripStartLocationZipCode'):
                return filter.originZip;
            case ('TripEndLocationCity'):
                return filter.destinationCity;
            case ('TripEndLocationState'):
                return filter.destinationState;
            case ('TripEndLocationCountry'):
                return filter.destinationCountry;
            case ('TripEndLocationZipCode'):
                return filter.destinationZip;
            case ('SupplierName'):
                return filter.supplierCustomer;
            case ('DCTripStop'):
                return filter.dcTripStopLocation;
            case ('MostRecentPostDC'):
                return filter.mostRecentPostDC;
            default:
                return [];
        }
    }

    onColumnChange(columns) {
        this.columnDefsChange.emit(columns);
    }

    onEditNote(shipment: Shipment) {
        this.shipmentNote = shipment?.notes;
        this.errorMessage = null;
        this.bsModalRef = this.modalSvc.show(this.notesModal,
            {
                class: 'modal-lg modal-dialog-centered',
                ignoreBackdropClick: true
            })
    }

    onSaveNotes() {
        if (!this.isHistorical) {
            const request = new SetTrackerNoteRequest();
            request.TrackerId = this.selectedShipment.trackerId
            request.note = this.shipmentNote;
            this.shpSvc.SetTrackerNote(request).pipe(take(1)).subscribe(
                n => {
                    if (n && n.ErrorCode === 0) {
                        this.bsModalRef.hide();
                        this.selectedShipment.notes = this.shipmentNote;
                    } else {
                        this.errorMessage = n.LocalizedErrorMessage;
                    }
                })
        }
    }
    onShutDownTracker(shipment: Shipment) {
        const request = new SetShutDownTrackerRequest();
        request.GlobalDeviceId = shipment?.trackerId;
        this.showPopups.showShutdownTracker(request, true, shipment);
    }

    openSubmitResponse(hasError: boolean, errorMessage: string, closeOnOk: boolean, headerTitle: string, debugErrorMessage: string = null) {
        const bsModalRef = this.modalSvc.show(
            ErrorMessageResponseComponent,
            {
                initialState: {
                    hasError,
                    errorMessage,
                    closeOnOk,
                    debugErrorMessage,
                    sucessMessage: !hasError ? errorMessage : null,//"Tracker shudown success!!!",
                    headerTitle: headerTitle
                },
                class: 'modal-sm modal-dialog-centered',
                ignoreBackdropClick: true
            }
        );

        bsModalRef.content.ok.pipe(take(1)).subscribe(n => this.onResponseOk(n));
    }

    onResponseOk(args: EventArgs) {
        args.modalRef.hide();

        if (!args.hasError) {
            if (args.closeOnOk && this.bsModalRef) {
                this.bsModalRef.hide();
            } else {
                // Do Nothing
            }
        }
    }
}

