export const API_HOST = 'https://api.domo.com';
export const EMBED_HOST = 'https://public.domo.com';
export const ACCESS_TOKEN_URL = `${API_HOST}/oauth/token?grant_type=client_credentials&scope=data%20user`;
export const EMBED_TOKEN_URL_DASHBOARD = `${API_HOST}/v1/stories/embed/auth`;
export const EMBED_URL_DASHBOARD = `${EMBED_HOST}/embed/pages/`;
export const EMBED_TOKEN_URL_CARD = `${API_HOST}/v1/cards/embed/auth`;
export const EMBED_URL_CARD = `${EMBED_HOST}/cards/`;
export const EMBED_TOKEN_URL = EMBED_TOKEN_URL_DASHBOARD;
export const EMBED_URL = EMBED_URL_DASHBOARD;
export const EMBED_CLIENT_ID = '8451e3e1-3647-4628-90cd-2ffa15fe1d56';
export const EMBED_SECRET_ID = '9a2c88c37f080dfd05b621f93ce760e0bf66bf1ff031214420a4a351b9f32975';
export const SIGMA_EMBED_PATH = 'https://app.sigmacomputing.com/embed/4-5mSz1u9e1WJvN1niMEl8az';
export const SIGMA_EMBED_SECRET = '582cd8f11727e8695fbdf6b0fa99ebc25e0ab80dea0a62854258a3981d4dda62bfdac4b134315ab4075133daeec743c94b301178c8e5c58e0ddd14db10eecb00';
export const SIGMA_CLIENT_ID = '1e14dd2414f40f63584a15ef519e90630f40a42e155e98a6a82cffdf9aeb77ec';